<template>
   
    <!-- ======= Portfolio Section ======= -->
    <section id="default" class="portfolio section-bg">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Portfolio</h2>
        </div>

        <!-- <div class="row">
          <div class="col-lg-12 d-flex justify-content-center" data-aos="fade-up" data-aos-delay="100">
            <ul id="portfolio-flters">
              <li data-filter="*" class="filter-active">All</li>
              <li data-filter=".filter-app">App</li>
              <li data-filter=".filter-card">Card</li>
              <li data-filter=".filter-web">Web</li>
            </ul>
          </div>
        </div> -->

        <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

          <div class="col-lg-3 col-md-4 portfolio-item filter-web"  v-for="(item,index) in projects" :key="index">
            <div class="portfolio-wrap">
              <img :src="item.logo" class="img-fluid" style="  display: block;margin-top: 20%;margin-left: auto;margin-right: auto;">
              <div class="portfolio-info">
                <h4>{{item.title}}</h4>
                <!-- <p>Web</p> -->
                <div class="portfolio-links">
                  <a href="#" @click="showProjectDetails(item.id)" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bxs-show"></i></a>
                  <a :href="item.url" class="portfolio-details-lightbox" target="_blank" data-glightbox="type: external" title="Portfolio Details"><i class="bx bx-link"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Portfolio Section -->
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'Portfolio',
  methods:{
    showProjectDetails(id){
       this.$router.push({ name: 'ProjectDetails', query: { uuid: id } })
    }
  },
  computed: {
    ...mapGetters({
      projects: 'getAllProjects'
    })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
