<template>
      <!-- ======= About Section ======= -->
    <section id="default" class="about" >
      <div v-if="project" class="container" data-aos="fade-up">
        <div class="section-title">
          <h2>{{project.title}}</h2>
        </div>

        <div class="row mt-5">
          <div class="col-lg-4">
            <img :src="project.logo" class="img-fluid img-border-radius" alt="" >
          </div>
          <div class="col-lg-8 pt-4 pt-lg-0 content">
            <div class="row mt-5">
              <div class="col-md-12">
                <p  v-html="project.content"></p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End About Section -->
</template>
<style>
.about{
  height:700px;
}
.img-border-radius{
  border-radius:50% !important
}
</style>

<script>
export default {
  data(){
    return{
    }
  },
 computed: {
    project(){
      return this.$store.getters.getProject(this.$route.query.uuid)
    }
  },
  mounted(){
    if(!this.project){
         this.$router.push({ name: 'Home'})
      }
  }
}

</script>