<template>
    <!-- ======= Resume Section ======= -->
    <section id="default" class="resume d-flex flex-column justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Résumé</h2>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <h3 class="resume-title">Expérience professionnelle</h3>
            <div class="resume-item" v-for="(item, index) in user.experiences" :key="index">
              <h4>{{item.title}} à {{item.company}}</h4>
              <h5>{{(!item.date_end) ? 'Depuis '+(item.date_start) : item.date_start}}  {{(item.date_end ) ? '- '+item.date_end : ''}} </h5>
              <!-- <p><em>{{item.company}} </em></p> -->
              <p v-html="item.content"></p>
            </div>
          </div>
            <div class="col-lg-6">
            <h3 class="resume-title">Éducation</h3>
            <div class="resume-item" v-for="(item, index) in user.educations" :key="index">
              <h4>{{item.title}}</h4>
              <h5>{{(!item.date_end) ? 'Depuis '+(item.date_start) : item.date_start}}  {{(item.date_end ) ? '- '+item.date_end : ''}}</h5>
              <p v-html="item.content"></p>
            </div>
          </div>
        </div>

      </div>
    </section><!-- End Resume Section -->
</template>
<style>

</style>
<script>
export default {
  name: 'Contact',
  computed:{
    user(){
      return this.$store.state.user;
    }
  }
}
</script>