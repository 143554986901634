<template>
   <!-- ======= Hero Section ======= -->
    <section id="hero" class="d-flex flex-column justify-content-center">
      <div class="container" data-aos="fade-up">
        <h1 v-if="user">{{user.name}}</h1>
        <p>Je suis <span class="typed"></span></p>
        <div v-if="user" class="social-links">
          <a v-if="user.twitter" :href="user.twitter" class="twitter" target="_blank"><i class="bx bxl-twitter"></i></a>
          <a  v-if="user.facebook" :href="user.facebook" class="facebook" target="_blank"><i class="bx bxl-facebook"></i></a>
          <a v-if="user.github"  :href="user.github" class="github" target="_blank"><i class="bx bxl-github"></i></a>
          <a v-if="user.skype"  :href="user.skype" class="google-plus" target="_blank"><i class="bx bxl-skype"></i></a>
          <a v-if="user.linkedin"  :href="user.linkedin" class="linkedin" target="_blank"><i class="bx bxl-linkedin"></i></a>
        </div>
      </div>
    </section>
    <!-- End Hero -->
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue';
import { getProfile } from '../services/UserService';
export default {
  name: 'Home',
  components: {
    Navbar
  },
  data(){
    return {
      typedObj:null
    }
  },
  methods:{
    loadData(){
      this.$store.dispatch('loadUser')
      getProfile().then(response=>{
          this.$store.commit('loadUserSuccessfully',response.data);
        }).catch(errors=>{
          this.$store.commit('loadUserFailed',errors);
      })
    },
    loadTypeAnimation(){
      const typed = document.querySelector('.typed')
      let typed_strings = this.$store.state.user.job.split('&')
      if (typed && typed_strings.length>0) {
        this.typedObj=new Typed('.typed', {
          strings: typed_strings,
          loop: true,
          typeSpeed: 100,
          backSpeed: 50,
          backDelay: 2000
        });
      }
  }
  },
  watch: {
    $route(to, from) {
     this.typedObj.destroy();
    },
    user(vewValue, oldValue) {
      this.loadTypeAnimation()
    }
  },
  computed:{
    user(){
      return this.$store.state.user;
    }
  },
  created: function (){
    this.loadData();
  },
  mounted(){
  }
}
</script>
