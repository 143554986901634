import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Skills from '../views/Skills.vue'
import Service from '../views/Service.vue'
import Contact from '../views/Contact.vue'
import Resume from '../views/Resume.vue'
import Portfolio from '../views/Portfolio.vue'
import ProjectDetails from '../views/ProjectDetails.vue'

import store from '../store/index'
if (process.env.NODE_ENV!='development' && window.location.protocol !== 'https:') window.location.href = process.env.VUE_APP_URL

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/skills',
        name: 'skills',
        component: Skills,
        meta: {
             dataLoaded:true,
        },
    },
    {
        path: '/services',
        name: 'Service',
        component: Service,
        meta: {
             dataLoaded:true,
        },
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
             dataLoaded:true,
        },
    },
    {
        path: '/resume',
        name: 'Resume',
        component: Resume,
        meta: {
             dataLoaded:true,
        },
    },
    {
        path: '/portfolio',
        name: 'Portfolio',
        component: Portfolio,
        meta: {
             dataLoaded:true,
        },
    },
    {
        path: '/projectDetails',
        name: 'ProjectDetails',
        component: ProjectDetails,
        props: true,
        meta: {
             dataLoaded:true,
        },
    },
    {
        path: '/about',
        name: 'About',
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue'),
            meta: {
                 dataLoaded: true,
            },
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: Home },
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
})
router.beforeEach((to, from, next) => {
    if (to.meta.dataLoaded) {
      if(to.meta.dataLoaded===true && !store.state.user){
        next("/")
      }
    }
    return next();
});
router.onError((err) => {
    print('<!-- router error: ' + err.message + ' -->')
 })
export default router