import axios from 'axios';
export function getProfile() {
    return new Promise((res, rej) => {
        axios.get(process.env.VUE_APP_URL_BACKEND+'/v1/profile')
            .then(response => {
                res(response.data);
            })
            .catch(error => {
                rej(error);
            });
    });
}

export function getCurrentProfile() {
    const user = localStorage.getItem('user');
    if (!user) {
        return null
    } else {
        return JSON.parse(user);
    }
}