<template>
  <!-- ======= Mobile nav toggle button ======= -->
  <!-- <button type="button" class="mobile-nav-toggle d-xl-none"><i class="bi bi-list mobile-nav-toggle"></i></button> -->
  <i class="bi bi-list mobile-nav-toggle d-xl-none"></i>
  <!-- ======= Header ======= -->
  <header id="header" class="d-flex flex-column justify-content-center">

    <nav id="navbar" class="navbar nav-menu">
      <ul>
        <li><router-link class="nav-link" to="/"><i class="bx bx-home"></i> <span>Accueil</span></router-link></li>
        <li><router-link class="nav-link" to="/about"><i class="bx bx-user"></i> <span>A propos</span></router-link></li>
        <li><router-link class="nav-link" to="/portfolio"><i class="bx bx-book-content"></i> <span>Portfolio</span></router-link></li>
        <li><router-link class="nav-link" to="/skills"><i class="bx bx-code"></i> <span>Compétences</span></router-link></li>
        <li><router-link class="nav-link" to="/resume"><i class="bx bx-file-blank"></i> <span>Résume</span></router-link></li>
        <li><router-link class="nav-link" to="/services"><i class="bx bx-server"></i> <span>Services</span></router-link></li>
        <li><router-link class="nav-link" to="/contact"><i class="bx bx-envelope"></i> <span>Contact</span></router-link></li>
      </ul>
    </nav><!-- .nav-menu -->

  </header><!-- End Header -->

</template>

<script>
export default {
  name: 'Navbar',
}
</script>

<style scoped>

</style>
