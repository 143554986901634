<template>
   <!-- ======= Contact Section ======= -->
    <section id="default" class="contact d-flex align-items-center justify-content-center">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Contact</h2>
        </div>

        <div class="row mt-1">

          <div class="col-lg-4">
            <div class="info">
              <div class="address">
                <i class="bi bi-geo-alt"></i>
                <h4>Localisation:</h4>
                <p>{{user.address}}</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>E-mail 1 :</h4>
                <p>{{user.gmail}}</p>
              </div>

              <div class="email">
                <i class="bi bi-envelope"></i>
                <h4>E-mail 2 :</h4>
                <p>{{user.outlook}}</p>
              </div>

              <div class="phone">
                <i class="bi bi-phone"></i>
                <h4>Téléphone:</h4>
                <p>{{user.phone}}</p>
              </div>

            </div>

          </div>

          <div class="col-lg-8 mt-5 mt-lg-0">

            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="row">
                <div class="col-md-6 form-group">
                  <input type="text" name="name" class="form-control" id="name" placeholder="Nom" required>
                </div>
                <div class="col-md-6 form-group mt-3 mt-md-0">
                  <input type="email" class="form-control" name="email" id="email" placeholder="E-mail" required>
                </div>
              </div>
              <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Sujet" required>
              </div>
              <div class="form-group mt-3">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Chargement ...</div>
                <div class="error-message"></div>
                <div class="sent-message">Votre message a été envoyé. Merci !</div>
              </div>
              <div class="text-center"><button type="submit">Envoyer le message</button></div>
            </form>

          </div>

        </div>

      </div>
    </section><!-- End Contact Section -->
</template>

<script>
export default {
  name: 'Contact',
  computed:{
    user(){
      return this.$store.state.user;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.contact{
  height:900px;
}
</style>
