import { createStore } from 'vuex'
import { getCurrentProfile } from '../services/UserService';
const courentUser = getCurrentProfile();
export default createStore({
    state: {
        user: courentUser,
        loading:false,
        errors:null
    },
    getters: {
        getProject: (state) => (uuid) => {
            return state.user.portfolios.find(item => item.id === uuid)
        },
        getAllProjects(state){
            return state.user.portfolios;
        }
    },
    mutations: {
        login(state) {
            state.loading = true;
            state.errors = null;
        },
        loadUser(state){
            state.loading = true;
            state.errors = null;
        },
        loadUserSuccessfully(state, payload){
            state.loading = false;
            state.errors = null;
            state.user = payload;
            localStorage.setItem('user',JSON.stringify(state.user))
            return state.user;
        },
        loadUserFailed(state, payload){
            state.loading = false;
            state.errors = payload;
            state.user=null;
        }
    },
    actions: {
        loadUser(context) {
            context.commit('loadUser');
        }
    },
    modules: {}
})