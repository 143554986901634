<template>
 <!-- ======= Skills Section ======= -->
    <section id="default" class="skills">
      <div class="container" data-aos="fade-up">

        <div class="section-title">
          <h2>Compétences</h2>
        </div>

        <div class="row skills-content">

          <div class="col-lg-6" v-for="(item,index) in skills" :key="index">

            <div class="progress">
              <span class="skill">{{item.name}} <i class="val">{{item.percentage}} %</i></span>
              <div class="progress-bar-wrap">
                <div class="progress-bar" role="progressbar" :aria-valuenow="item.percentage" aria-valuemin="0" aria-valuemax="100"></div>
              </div>
            </div>

            
          </div>

        </div>

      </div>
    </section><!-- End Skills Section -->
</template>

<style>

</style>
<script>
export default {
  name: 'Skills',
  data(){
    return {
      typedObj:null,
    }
  },
  watch: {
    $route(to, from) {
     this.typedObj.destroy();
    }
  },
  computed:{
    skills(){
      return this.$store.state.user.skills
    }
  },
  mounted(){
  let skilsContent = document.querySelector('.skills-content');
  if (skilsContent) {
    this.typedObj=new Waypoint({
      element: skilsContent,
      offset: '80%',
      handler: function(direction) {
        let progress = document.querySelectorAll('.progress .progress-bar');
        progress.forEach((el) => {
          el.style.width = el.getAttribute('aria-valuenow') + '%'
        });
      }
    })
  }
  }
}
</script>