<template>
  <div id="nav">
    <Navbar/>
  </div>
  <router-view/>
</template>
<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'

export default {
  name: 'App',
  components: {
    Navbar
  },
  methods:{
    loadMeta(){
     document.querySelector('meta[name="description"]').setAttribute('content',this.user?.about);
     document.querySelector('meta[property="og:description"]').setAttribute('content',this.user?.about);
     document.querySelector('meta[property="og:title"]').setAttribute('content',this.user?.name);
     document.querySelector('meta[property="og:image"]').setAttribute('content',this.user?.avatar);
    }
  },
  computed:{
    user(){
      return this.$store.state.user;
    }
  }, 
  mounted() {
    setInterval(()=>{
      this.loadMeta()
    },500)
  }, 
  watch: {
    user(vewValue, oldValue) {
      this.loadMeta()
    }
  },
}
</script>
<style>

</style>
